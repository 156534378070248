import React from "react"

const Lock = props => {
  return (
    <svg css={["fill: currentColor"]} {...props}>
      <path d="M26.147 14.277h-1.183V9.715A9.369 9.369 0 0015.418 0C9.8 0 5.956 4.351 5.956 9.715v4.52H4.773A4.8 4.8 0 000 19.008v14.278a4.8 4.8 0 004.773 4.773h21.374a4.8 4.8 0 004.773-4.773V19.008a4.759 4.759 0 00-4.773-4.731zm-17.826 0V9.715c0-4.055 2.788-7.35 7.1-7.35a7.035 7.035 0 017.179 7.35v4.52H8.321v.042zm20.191 19.009a2.362 2.362 0 01-2.365 2.365H4.773a2.362 2.362 0 01-2.365-2.365V19.008a2.362 2.362 0 012.365-2.365h21.374a2.362 2.362 0 012.365 2.365zm-10.687-9.5a2.378 2.378 0 01-1.183 2.07v3.886a1.183 1.183 0 11-2.365 0v-3.891a2.379 2.379 0 011.183-4.435 2.31 2.31 0 012.366 2.365z" />
    </svg>
  )
}

export default Lock
