import React from "react"

const Dscvr = props => {
  return (
    <svg css={["fill: currentColor"]} {...props} viewBox="0 0 25.16 25.15">
      <path d="M11 11.75c-.05 1.56.1 2.52.13 4.07 0 .47-.14.55-.56.43a5.85 5.85 0 01-2.21-1.15.73.73 0 01-.3-.59c-.08-2.88-.17-5.16-.26-8 0-.2 0-.36.19-.46 2.32-1.21 4.76-1.95 7.3-.95a7.75 7.75 0 015.18 6.76 7.86 7.86 0 01-5 8.18A8.8 8.8 0 017.5 19a10.23 10.23 0 01-4.92-7.7A10 10 0 019.5.47a11.81 11.81 0 0110.89 2.27 11.92 11.92 0 014.63 8 12.24 12.24 0 01-4.33 11.45A11.78 11.78 0 0114 25.08a12.34 12.34 0 01-9.23-2.65 12.28 12.28 0 01-4.59-7.78A12.15 12.15 0 011.23 7.2c.06-.13.09-.37.29-.32a.53.53 0 01.39.46 2.22 2.22 0 01-.15.89 11.57 11.57 0 00.91 8.91 10.51 10.51 0 008.22 5.66 10.23 10.23 0 009.13-3 10 10 0 002.86-6.35A10.3 10.3 0 0015 2.55a9.39 9.39 0 00-5.33.13 7.27 7.27 0 00-4.1 3.79 8.16 8.16 0 002.12 10 6.81 6.81 0 007.86.33 5 5 0 001.86-5.08 4.73 4.73 0 00-4-3.72 5.37 5.37 0 00-2.07.15c-.31.08-.38.22-.38.52.04 1.01.04 2.05.04 3.08z" />
    </svg>
  )
}

export default Dscvr
