import React from "react"
import tw from "twin.macro"
import NavLinks from './NavLinks'

const HeaderNav = props => {
  return (
    <nav {...props}>
      <NavLinks css={[tw`inline`]} />
    </nav>
  )
}

export default HeaderNav
