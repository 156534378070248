import React from "react"
import tw, { GlobalStyles, css } from "twin.macro"
import { Global } from "@emotion/react"

const AllGlobalStyles = () => {
  const customStyles = css`
    $svgBlur: url("#svgBlur");

    html,
    body {
      font-size: 16px;
      ${tw`text-primary`}
    }

    p {
      ${tw`text-lg mb-8 !leading-normal`}

      a {
        ${tw`hover:(border-secondary) active:(border-primary) focus:(border-primary)`}
      }

      &:last-child {
        ${tw`mb-0`}
      }
    }

    a {
      ${tw`border-b-2 border-transparent transition cursor-pointer`}
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      ${tw`!leading-snug`}
    }

    h1,
    .h1 {
      ${tw`text-4xl sm:text-5xl md:text-6xl`}
    }

    h2,
    .h2 {
      ${tw`text-3xl sm:text-4xl	md:text-5xl`}
    }

    h3,
    .h3 {
      ${tw`text-2xl sm:text-3xl`}
    }

    h4,
    .h4 {
      ${tw`text-xl sm:text-2xl`}
    }

    .activeHeaderLink {
      ${tw`!border-secondary`}
    }

    .form-field {
      ${tw`mb-10`}

      &:last-of-type {
        ${tw`!mb-0`}
      }
    }

    .blurred-bg {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }
  `
  return (
    <>
      <GlobalStyles />
      <Global styles={customStyles} />
    </>
  )
}

export default AllGlobalStyles
