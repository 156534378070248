import React from "react"

const Gavel = props => {
  return (
    <svg css={["fill: currentColor"]} {...props}>
      <path
        id="Path_78"
        data-name="Path 78"
        class="cls-1"
        d="M343.047 364.815h-.069a3.582 3.582 0 00-3.57-3.424h-12.737a3.581 3.581 0 00-3.567 3.424h-.072a2.994 2.994 0 00-2.985 2.983v1.634a.9.9 0 00.9.895h24.195a.9.9 0 00.9-.895V367.8a2.994 2.994 0 00-2.995-2.985zm-19.056 1.791h19.055a1.2 1.2 0 011.2 1.192v.739h-22.4v-.737a1.2 1.2 0 011.195-1.192zm.9-1.791a1.79 1.79 0 011.779-1.634h12.738a1.79 1.79 0 011.779 1.634z"
        transform="translate(-319.797 -327.356)"
      />
      <path
        id="Path_78_-_Outline"
        data-name="Path 78 - Outline"
        class="cls-1"
        d="M326.671 361.141h12.737a3.833 3.833 0 013.8 3.428 3.245 3.245 0 013.072 3.231v1.632a1.146 1.146 0 01-1.146 1.145h-24.192a1.146 1.146 0 01-1.145-1.145V367.8a3.245 3.245 0 013.075-3.229 3.831 3.831 0 013.8-3.428zm18.466 8.935a.646.646 0 00.646-.645V367.8a2.743 2.743 0 00-2.736-2.735h-.309l-.01-.239a3.332 3.332 0 00-3.32-3.184h-12.736a3.319 3.319 0 00-3.318 3.185l-.01.239h-.312a2.743 2.743 0 00-2.732 2.734v1.634a.646.646 0 00.645.645zm-18.466-7.145h12.738a2.049 2.049 0 012.028 1.862l.023.271h-16.84l.023-.271a2.049 2.049 0 012.028-1.862zm14.22 1.634a1.549 1.549 0 00-1.482-1.134h-12.738a1.548 1.548 0 00-1.481 1.134zm-17.859 1.791h20.014a1.448 1.448 0 011.445 1.441v.989h-22.9v-.986a1.448 1.448 0 011.441-1.444zm20.959 1.931v-.487a.947.947 0 00-.945-.942h-20.013a.947.947 0 00-.945.943v.488z"
        transform="translate(-319.797 -327.356)"
      />
      <path
        id="Path_79"
        data-name="Path 79"
        class="cls-1"
        d="M368.878 332.574a3.328 3.328 0 00-1.124-2.3l-14.564-12.886 2.763-3.123.773.684a1.471 1.471 0 00.977.372h.09a1.476 1.476 0 001.019-.5l2.131-2.408a1.486 1.486 0 00-.128-2.089l-11.3-9.99a1.486 1.486 0 00-2.088.129l-2.13 2.409a1.477 1.477 0 00.129 2.087l.774.685-9.965 11.267-.774-.685a1.478 1.478 0 00-2.089.128l-2.13 2.409a1.484 1.484 0 00.129 2.088l11.293 9.992a1.474 1.474 0 00.979.371h.091a1.484 1.484 0 001.019-.5l2.13-2.408a1.481 1.481 0 00-.129-2.088l-.774-.684 2.763-3.124 14.563 12.88a3.32 3.32 0 002.209.842h.007c.068 0 .138 0 .208-.007a3.352 3.352 0 003.139-3.548zm-12.409-20.244l-8.4-7.434a.927.927 0 00-.192-.128l-1.043-.918 1.741-1.969 10.854 9.554-1.719 1.988-1.209-1.065c-.015-.008-.022-.019-.032-.028zm-4.469 6.4l14.627 12.938a1.56 1.56 0 01-1.006 2.7 1.578 1.578 0 01-1.124-.422l-14.563-12.88zm-17.488-.958l1.183 1.041c.01.01.018.022.029.031l8.4 7.435a.906.906 0 00.158.1l1.081.952-1.719 1.988-10.88-9.574zm10.131 6.572l-7.063-6.248 9.964-11.266 7.064 6.249zm-1 6.594z"
        transform="translate(-324.672 -299.716)"
      />
      <path
        id="Path_79_-_Outline"
        data-name="Path 79 - Outline"
        class="cls-1"
        d="M365.531 336.378h-.007a3.57 3.57 0 01-2.376-.9l-14.375-12.718-2.432 2.75.587.519a1.731 1.731 0 01.15 2.441l-2.13 2.408a1.734 1.734 0 01-1.192.581c-.04 0-.07.005-.106.005a1.725 1.725 0 01-1.145-.434l-11.293-9.992a1.735 1.735 0 01-.151-2.44l2.13-2.409a1.722 1.722 0 011.189-.581h.1a1.74 1.74 0 011.155.434l.587.519 9.634-10.892-.587-.519a1.727 1.727 0 01-.151-2.439l2.13-2.409a1.736 1.736 0 012.44-.151l11.3 9.99a1.737 1.737 0 01.15 2.441L359 314.987a1.725 1.725 0 01-1.188.58h-.108a1.723 1.723 0 01-1.146-.435l-.586-.518-2.432 2.749 14.376 12.716a3.6 3.6 0 01-2.165 6.289c-.071.008-.151.01-.22.01zm-16.8-14.325L363.48 335.1a3.071 3.071 0 002.044.779h.007c.063 0 .128 0 .192-.006a3.1 3.1 0 001.865-5.415l-14.751-13.048 3.095-3.5.961.849a1.222 1.222 0 00.812.309h.079a1.223 1.223 0 00.845-.411l2.131-2.409a1.236 1.236 0 00-.107-1.736l-11.295-9.99a1.236 1.236 0 00-1.735.108l-2.13 2.409a1.227 1.227 0 00.107 1.734l.961.85-10.3 11.641-.962-.85a1.228 1.228 0 00-1.736.107l-2.13 2.408a1.234 1.234 0 00.107 1.735l11.293 9.992a1.226 1.226 0 00.813.308h.072a1.234 1.234 0 00.85-.413l2.13-2.408a1.231 1.231 0 00-.107-1.735l-.961-.85zm16.864 12.564a1.82 1.82 0 01-1.261-.487l-14.748-13.044 2.4-2.71 14.814 13.109a1.81 1.81 0 01-1.167 3.133h-.04zm-15.3-13.574l14.381 12.719a1.329 1.329 0 00.923.355h.024a1.309 1.309 0 00.844-2.265l-14.437-12.77zm-6.615 8.624l-11.258-9.906 2.076-2.343 1.381 1.216.026.028 8.4 7.428a.412.412 0 00.061.035l.055.03.022.013 1.286 1.132zm-10.551-9.95l10.5 9.241 1.392-1.61-.875-.77-.018-.009a.765.765 0 01-.16-.1l-8.405-7.436a.409.409 0 01-.039-.041l-.984-.866zm11.543 4.978l-7.438-6.579 10.3-11.641 7.439 6.58zm-6.732-6.622l6.689 5.916 9.634-10.891-6.69-5.917zm19.793-4.3l-1.41-1.242-.023-.025-8.4-7.428a.675.675 0 00-.141-.093l-.027-.014-1.254-1.1 2.072-2.344 11.227 9.883zm-1.058-1.6l1.009.889 1.392-1.61-10.481-9.226-1.41 1.594.833.733a1.139 1.139 0 01.215.147l8.4 7.434a.4.4 0 01.039.047z"
        transform="translate(-324.672 -299.716)"
      />
    </svg>
  )
}

export default Gavel
