import React, {useState, useEffect} from "react"
import tw from "twin.macro"
import NavLinks from "../NavLinks"

const NavDrawer = ({open, handleLinkClick}) => {
    const [drawerOpen, setDrawerOpen] = useState(open);

    useEffect(() => {
        setDrawerOpen(open);
    }, [open])

    const handleClicked = (drawerState) => {
        setDrawerOpen(drawerState);
        handleLinkClick(drawerState)
    }

    return (
        <div css={[
            tw`absolute top-20 md:top-28 -mt-px right-0 z-10 h-screen w-3/4 bg-white transform translate-x-full transition-transform duration-200`,
            drawerOpen && tw`right-0 transform translate-x-0 transition-transform duration-200`,
            ]} 
        >
            <NavLinks css={[tw`block text-right pr-4`]} handleClick={ handleClicked} isOpen={open}  />
        </div>
    )
}

export default NavDrawer
