import React from "react"

const Brick = props => {
  return (
    <svg css={["fill: currentColor"]} {...props}>
      <path d="M43.308 4.818h-.936v-3.9A.92.92 0 0041.454 0h-7.966a.92.92 0 00-.918.918v3.9h-4.726v-3.9A.92.92 0 0026.926 0h-7.965a.92.92 0 00-.918.918v3.9h-4.727v-3.9A.92.92 0 0012.398 0H4.433a.92.92 0 00-.918.918v3.9h-.936A2.579 2.579 0 000 7.388v12.665a2.581 2.581 0 002.579 2.577h40.739a2.581 2.581 0 002.582-2.577V7.388a2.593 2.593 0 00-2.592-2.57zm-8.9-2.983h6.131v2.983h-6.133zm-14.528 0h6.131v2.983h-6.133zm-14.528 0h6.131v2.983H5.35zm38.7 18.226a.746.746 0 01-.743.743H2.579a.752.752 0 01-.743-.743V7.397a.746.746 0 01.743-.743h40.729a.746.746 0 01.743.743v12.665z" />
    </svg>
  )
}

export default Brick
