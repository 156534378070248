import React from "react"

const Twitter = props => {
  return (
    <svg css={["fill: currentColor"]} {...props} viewBox="0 0 31.76 25.81">
      <path
        data-name="Path 487"
        d="M10 25.81c12 0 18.54-9.93 18.54-18.54v-.85a13.35 13.35 0 003.25-3.37 13.13 13.13 0 01-3.75 1A6.55 6.55 0 0030.88.47a12.86 12.86 0 01-4.14 1.58 6.52 6.52 0 00-11.1 6A18.53 18.53 0 012.21 1.19a6.52 6.52 0 002 8.7 6.42 6.42 0 01-3-.82v.09a6.51 6.51 0 005.29 6.38 6.5 6.5 0 01-2.94.11 6.52 6.52 0 006.08 4.53A13 13 0 011.55 23 13.3 13.3 0 010 22.88a18.43 18.43 0 0010 2.92"
      />
    </svg>
  )
}

export default Twitter
