import React from "react"

const Dstrkt = props => {
  return (
    <svg css={["fill: currentColor"]} {...props} viewBox="0 0 34.94 34.94">
      <rect width="34.94" height="34.94" rx="9.56" />
      <path
        d="M8.55 20.76v-4.49c0-.57.07-.64.65-.64h1.48c.67 0 .75.07.75.73v5.93c0 .68.05.74.75.74h5.92c.6 0 .64 0 .64.64v1.7c0 .42-.18.56-.57.56h-9c-.51 0-.6-.1-.6-.6v-4.54zM17.47 11.88h4.48c.55 0 .63.08.63.61v8.93c0 .57-.08.65-.64.65H20.5c-.81 0-.81 0-.81-.84v-5.77c0-.63-.05-.68-.66-.68h-6c-.61 0-.71-.09-.71-.69v-1.62c0-.54.15-.59.58-.59 1.54.01 3.1 0 4.57 0zM23.47 9.54v-1a.39.39 0 01.44-.44h2a.4.4 0 01.44.44v2a.41.41 0 01-.46.43h-2a.43.43 0 01-.47-.48z"
        fill="#fff"
      />
    </svg>
  )
}

export default Dstrkt
