import React, {useState} from 'react'
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import { Link, scrollSpy } from "react-scroll"

const NavLinks = (props) => {
    const data = useStaticQuery(graphql`
    {
      allNavigationYaml {
        nodes {
          label
          url
        }
      }
    }
  `)
    const handleClick = () => {
      if(window.innerWidth < 1024) {
        props.handleClick(!props.isOpen)
      }
      
    }
    return (
        <ul>
            {data.allNavigationYaml.nodes.map(item => (
                <li {...props}>
                <Link
                  to={item.url.split("#")[1]}
                  smooth={true}
                  duration={750}
                  spy={true}
                  offset={-130}
                  css={[
                    tw`inline-block mx-4 my-2 py-1 font-bold border-b-4 border-transparent hover:(border-secondary) active:(border-primary) focus:(border-primary)`,
                  ]}
                  activeClass="activeHeaderLink"
                  onClick={() => handleClick()}
                >
                  {item.label}
                </Link>
              </li>
            ))}
            
        </ul>
    )
}

export default NavLinks
