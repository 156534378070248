import React from "react"

const LinkedIn = props => {
  return (
    <svg css={["fill: currentColor"]} {...props} viewBox="0 0 33.97 33.97">
      <rect
        x="4.92"
        y="12.48"
        width="5.59"
        height="17.75"
        rx=".89"
        fill="#fff"
      />
      <path
        d="M7.71 3.74a3.62 3.62 0 000 7.24 3.62 3.62 0 000-7.24zM30.4 21.69c0-3.36.05-9.47-6.5-9.47-2.95 0-4.34 1.08-5.27 2.48v-1.4a.82.82 0 00-.82-.82h-3.95a.83.83 0 00-.83.82v16.11a.83.83 0 00.83.82h3.95a.82.82 0 00.82-.82v-8.64c.1-1.16.61-3.74 3.13-3.74 3.25 0 2.92 3.78 2.92 4.89v7.49a.82.82 0 00.82.82h4.08a.83.83 0 00.83-.82z"
        fill="#fff"
      />
      <path d="M30.9 0H3.07A3.08 3.08 0 000 3.07V30.9A3.07 3.07 0 003.07 34H30.9a3.06 3.06 0 003.1-3.1V3.07A3.07 3.07 0 0030.9 0zM10.51 29.34a.89.89 0 01-.89.89H5.81a.89.89 0 01-.89-.89v-16a.89.89 0 01.89-.9h3.81a.89.89 0 01.89.9zM7.72 11a3.62 3.62 0 113.62-3.62A3.62 3.62 0 017.71 11zm21.86 19.23H25.5a.82.82 0 01-.82-.82v-7.49c0-1.11.33-4.89-2.92-4.89-2.52 0-3 2.58-3.13 3.74v8.64a.82.82 0 01-.82.82h-3.95a.83.83 0 01-.83-.82V13.3a.83.83 0 01.83-.82h3.95a.82.82 0 01.82.82v1.4c.93-1.4 2.32-2.48 5.27-2.48 6.55 0 6.5 6.11 6.5 9.47v7.72a.83.83 0 01-.82.82z" />
    </svg>
  )
}

export default LinkedIn
