import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import NavDrawer from './NavDrawer'
import { Turn as Hamburger } from 'hamburger-react'

const MobileHeaderNav = ({...css}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleLinkClick = (drawerState) => {
      setIsOpen(drawerState);
    }

    return (
      <>
        <nav {...css}>
          <Hamburger
            onClick={() => setIsOpen(!isOpen)}
            css={[tw`p-1 float-right rounded`]}
            toggled={isOpen}
            toggle={setIsOpen}
            size={24}
            color="#0B1B84"
            label="Toggle menu drawer"
          />
            <NavDrawer open={isOpen} handleLinkClick={handleLinkClick} />
        </nav>
        <div css={[
          tw`hidden absolute h-screen w-screen bg-black opacity-0 top-20 md:top-28 left-0 z-0 transition-opacity`,
          isOpen && tw`block opacity-80 transition-opacity`
          ]}></div>
        </>
    )
}

export default MobileHeaderNav
