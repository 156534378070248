import * as React from "react"
import { Link } from "gatsby"
import HeaderNav from "../HeaderNav"
import MobileHeaderNav from "../HeaderNav/Mobile/MobileHeaderNav"
import Logo from "../Logo"
import tw from "twin.macro"
import Container from "../Container"

const Header = () => (
  <header css={[tw`md:h-28 pb-4 pt-4 md:py-8`]}>
    <div
      css={[
        tw`fixed top-0 w-full md:h-28 pb-4 pt-4 md:py-8 bg-white bg-opacity-90 z-50`,
      ]}
      className="blurred-bg"
    >
      <Container wide css={[tw`grid grid-cols-2 items-center lg:grid-cols-4`]}>
        <Link to="/">
          <Logo css={[tw`w-40 md:(h-10 w-auto)`]} />
        </Link>
        <HeaderNav css={[tw`hidden lg:flex lg:justify-end lg:col-span-3`]} />
        <MobileHeaderNav css={[tw`flex justify-end lg:hidden`]} />
      </Container>
    </div>
  </header>
)

export default Header
