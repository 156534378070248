import React from "react"

const Facebook = props => {
  return (
    <svg css={["fill: currentColor"]} {...props} viewBox="0 0 35.46 35.46">
      <path d="M33.51 35.46a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v31.5a2 2 0 002 2h31.51z" />
      <path
        d="M24.47 35.45V21.72h4.61l.69-5.35h-5.3V13c0-1.55.43-2.61 2.65-2.61H30V5.56a40.6 40.6 0 00-4.12-.21c-4.09 0-6.89 2.5-6.89 7.08v3.94h-4.67v5.35h4.62v13.74z"
        fill="#fff"
      />
    </svg>
  )
}

export default Facebook
