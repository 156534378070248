import React from "react"

const Document = props => {
  return (
    <svg css={["fill: currentColor"]} {...props}>
      <path d="M3.737 42.843a3.741 3.741 0 01-3.738-3.737V3.737A3.742 3.742 0 013.736-.001h18.57a1.078 1.078 0 01.766.318l8.842 8.843a1.076 1.076 0 01.318.767v29.18a3.741 3.741 0 01-3.737 3.737zM2.168 3.737v35.369a1.571 1.571 0 001.569 1.569H28.5a1.571 1.571 0 001.569-1.569v-28.1h-5.106a3.711 3.711 0 01-2.641-1.1 3.708 3.708 0 01-1.1-2.641V2.159H3.738a1.571 1.571 0 00-1.569 1.578zm21.221 3.537a1.57 1.57 0 001.569 1.568h3.572l-5.141-5.141zm-9.043 30.263c-1.111 0-1.57-1.088-1.943-2.273-.546 1.07-1.289 2.273-2.477 2.273a3.42 3.42 0 01-2.035-.949 9.105 9.105 0 01-.852.732 1.072 1.072 0 01-.65.217 1.092 1.092 0 01-.868-.434 1.086 1.086 0 01.218-1.517 7.474 7.474 0 00.7-.608 5.568 5.568 0 01-1.137-2.947 4.13 4.13 0 01.983-2.654 2.713 2.713 0 011.87-1.083 3.069 3.069 0 011.835.645 2.69 2.69 0 011.018 2.208 7.141 7.141 0 01-1.686 3.817 2.28 2.28 0 00.548.382 13.863 13.863 0 00.859-1.591l.015-.03c.542-1.1.932-1.893 1.831-1.893 1.054 0 1.37 1.049 1.737 2.265v.008c.086.288.238.8.377 1.146 1.075.035 1.945.053 2.946.074.577.011 1.173.023 1.86.04h.05l.044.025c.028.016.063.033.1.051a1.065 1.065 0 01.678 1.123.906.906 0 01-.734.906 1.2 1.2 0 00-.133.051l-.043.022h-3.522c-.294.006-.712.003-1.588-.005zm-6.872-5.505a2.616 2.616 0 00.425 1.206 4.68 4.68 0 00.943-2.09.615.615 0 00-.62-.682 2.082 2.082 0 00-.748 1.566zm5.99-5.1a1.086 1.086 0 01-1.085-1.084 1.086 1.086 0 011.085-1.084h12.38a1.085 1.085 0 011.084 1.084 1.086 1.086 0 01-1.084 1.084zM6.39 21.626a1.086 1.086 0 01-1.085-1.084 1.086 1.086 0 011.085-1.084h19.454a1.087 1.087 0 011.084 1.084 1.086 1.086 0 01-1.084 1.084zm0-5.305a1.086 1.086 0 01-1.085-1.084 1.086 1.086 0 011.085-1.084h19.454a1.086 1.086 0 011.084 1.084 1.086 1.086 0 01-1.084 1.084zm0-5.305a1.086 1.086 0 01-1.085-1.084A1.086 1.086 0 016.39 8.848H17a1.086 1.086 0 011.084 1.084A1.086 1.086 0 0117 11.016z" />
    </svg>
  )
}

export default Document
