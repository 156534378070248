import React, { useState } from "react"
import tw, { styled } from "twin.macro"

const field = FieldComponent => props => {
  const [isActive, setIsActive] = useState(props.error)

  return (
    <div css={[tw`relative`]} className="form-field">
      <label
        for={props.name}
        css={[
          tw`absolute top-5 left-2 md:left-5 -mt-1 z-20 text-lg transition-all ease-out`,
          isActive && tw`-top-6 left-0 text-sm`,
          props.error && tw`border-red text-red`,
        ]}
      >
        {props.error || props.label}
      </label>
      <FieldComponent
        onFocus={e => setIsActive(true)}
        onBlur={e => (e.target.value === "" ? setIsActive(false) : null)}
        id={props.name}
        css={[props.error && tw`ring-2 ring-red text-red`]}
        {...props}
      />
    </div>
  )
}

export default field
