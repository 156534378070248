import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

const Button = styled.button(
  ({ primary, secondary, small, large, block, submitting }) => [
    tw`inline-block px-6 py-3 rounded-lg font-bold uppercase
    transition-all ease-in duration-100`,
    primary &&
      tw`text-white bg-primary
    hover:(bg-secondary text-primary)
    focus:(bg-primary text-white)
    active:(bg-secondary text-white)`,
    secondary &&
      tw`text-white bg-secondary hover:(bg-secondary) focus:(bg-secondary) active:(bg-secondary)`,
    small && tw`text-xs`,
    large && tw`text-xl md:text-2xl px-8 py-5`,
    block && tw`block w-full`,
    submitting &&
      tw`text-primary animate-gradient-xy bg-gradient-to-bl from-secondary to-green`,
  ]
)

export const ButtonDiv = Button.withComponent("div")
export const ButtonLink = Button.withComponent(Link)

export default Button
