import React from "react"

const Scales = props => {
  return (
    <svg css={["fill: currentColor"]} {...props}>
      <path d="M44.041 42.488a12.888 12.888 0 01-9.032-3.748l-1.417-1.417a1.284 1.284 0 01-.252-1.339l9.014-21.034-10.307-1.518-.169.3a4.8 4.8 0 01-4.144 2.438 4.67 4.67 0 01-2.982-1.06 4.733 4.733 0 01-1.624-2.613l-.075-.335-10.215-1.5 8.77 20.1a1.265 1.265 0 01-.287 1.39l-1.479 1.366-.014.013a12.622 12.622 0 01-8.974 3.745 12.756 12.756 0 01-4.894-.964 13.185 13.185 0 01-4.2-2.783L.343 32.111a1.28 1.28 0 01-.253-1.338l9.707-22.17a.565.565 0 00.067-.108 1.56 1.56 0 00.057-.083l.015-.022a.983.983 0 00.237-.187l.028-.029a.74.74 0 00.2-.142l.005-.005.012-.007h.007l.012-.007h.275l.057-.057h.273L23.33 9.719l.158-.331a4.679 4.679 0 012.688-2.375l.336-.117V1.219A1.19 1.19 0 0127.729.002a1.19 1.19 0 011.216 1.217v5.675l.333.119a4.816 4.816 0 013.109 3.68l.065.355 11.835 1.715h.07a.535.535 0 00.171.087l.042.023.029.028a1.223 1.223 0 00.189.147l.011.008.013.009.025.017.248.249a.83.83 0 00.084.133l9.618 22.5a1.284 1.284 0 01-.288 1.352l-1.431 1.43a12.726 12.726 0 01-4.164 2.781 12.705 12.705 0 01-4.863.961zm-6.744-4.807l1.5.926a9.982 9.982 0 005.24 1.447 9.948 9.948 0 005.3-1.447l1.5-.926H37.298zm-.736-3.49v.557l.5.1v.341H51.78l-7.738-18.141-7.287 17.142zM4.053 32.474l1.5.926a9.949 9.949 0 005.3 1.447 9.982 9.982 0 005.241-1.447l1.5-.926H4.048zm6.8-20.267L3.11 30.041h15.486l-7.744-17.834zm16.88-3.021a2.277 2.277 0 00-2.274 2.274 2.276 2.276 0 002.274 2.273 2.276 2.276 0 002.273-2.273 2.276 2.276 0 00-2.272-2.275z" />
    </svg>
  )
}

export default Scales
