import React from "react"

const Crypto = props => {
  return (
    <svg css={["fill: currentColor"]} {...props}>
      <path
        id="Path_40"
        data-name="Path 40"
        class="cls-1"
        d="M588.8 264.726v-.339a3.807 3.807 0 00-3.8-3.8h-.527v-1.731h-2.411v1.731h-.525a3.8 3.8 0 100 7.6H585a1.392 1.392 0 010 2.784h-3.463a1.394 1.394 0 01-1.392-1.392v-.34h-2.41v.34a3.806 3.806 0 003.8 3.8h.525v1.732h2.411v-1.732H585a3.8 3.8 0 000-7.6h-3.463a1.392 1.392 0 110-2.783H585a1.393 1.393 0 011.391 1.392v.339z"
        transform="translate(-556.093 -231.153)"
      />
      <path
        id="Path_41"
        data-name="Path 41"
        class="cls-1"
        d="M585.148 218.048a15.07 15.07 0 00-12.123 6.135 15.048 15.048 0 10-13.86 23.863 14.853 14.853 0 00-1.191 5.831 15.04 15.04 0 0023.794 12.245l.276-.2-1.4-1.96-.277.2a12.639 12.639 0 112.276-2.083l-.221.258 1.834 1.565.22-.259a15.055 15.055 0 003.609-9.767 14.857 14.857 0 00-1.188-5.825 15.02 15.02 0 006.99-2.705l.276-.2-1.4-1.961-.276.2a12.561 12.561 0 01-6.82 2.341 15.142 15.142 0 00-10.881-6.793 15.165 15.165 0 001.181-5.833 14.84 14.84 0 00-1.567-6.653 12.64 12.64 0 1120.36 14.853l-.221.259 1.833 1.565.221-.258a15.045 15.045 0 00-11.445-24.822zM560.4 245.725a12.642 12.642 0 1111.765-6.858 15.059 15.059 0 00-11.765 6.858z"
        transform="translate(-545.855 -218.048)"
      />
      <path
        id="Path_42"
        data-name="Path 42"
        class="cls-1"
        d="M568.3 231.729l.527-1.553-1.575-.535-.5 1.474-1.093-.371c.175-.517.337-.992.5-1.466l-1.586-.538-.515 1.515-2.6-.883-.515 1.519c1.345.585 1.064.684.71 1.766-.631 1.931-1.3 3.849-1.954 5.772-.37 1.089-.371 1.087-1.485.821-.032-.007-.071.019-.129.036l-.506 1.488 2.635.894-.517 1.521 1.583.538.5-1.483 1.092.371-.5 1.473 1.575.535.54-1.592c1.734.212 3.143-.23 3.888-1.862a2.86 2.86 0 00-1.159-3.835 2.9 2.9 0 002.838-2.39c.296-1.509-.519-2.478-1.754-3.215zm-2.32 7.871a1.6 1.6 0 01-1.759 1.28 10.053 10.053 0 01-1.9-.551.638.638 0 01-.24-.571c.271-.922.6-1.828.922-2.777a23.154 23.154 0 012.2.764 1.418 1.418 0 01.777 1.855zm1.378-4.673a1.3 1.3 0 01-1.431.965c-.774-.126-1.527-.379-2.339-.59l.98-2.887c.805.337 1.6.616 2.348 1a1.193 1.193 0 01.442 1.507z"
        transform="translate(-549.621 -221.481)"
      />
      <path
        id="Path_43"
        data-name="Path 43"
        class="cls-1"
        d="M602.688 239.5q1.491-2.259 3.022-4.49c.228-.331.134-.468-.159-.66q-3.957-2.59-7.9-5.2c-.126-.083-.261-.155-.449-.265-.035.223-.07.385-.086.549-.305 3.124-.6 6.249-.92 9.373-.038.377.039.523.443.555 1.785.136 3.567.3 5.349.47a.653.653 0 00.7-.332z"
        transform="translate(-562.019 -221.528)"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        class="cls-1"
        d="M606.677 238.994a.623.623 0 00-.32.261c-.969 1.436-1.944 2.869-2.9 4.315a.725.725 0 01-.766.383c-1.342-.138-2.688-.247-4.032-.364-.475-.041-.951-.073-1.427-.11l-.037.135 7.911 4.406 1.571-9.026z"
        transform="translate(-562.343 -224.775)"
      />
    </svg>
  )
}

export default Crypto
