import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import SocialLinks from "../SocialLinks"
import FooterNav from "../FooterNav"
import EmailSubmissionForm from "../EmailSubmissionForm"
import Logo from "../Logo"
import tw from "twin.macro"
import Container from "../Container"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allHomeYaml {
        nodes {
          footer {
            jobs {
              paragraph
            }
          }
        }
      }
    }
  `)

  const footer = data.allHomeYaml.nodes[0].footer

  return (
    <>
      <footer css={[tw`text-white bg-primary`]}>
        <Container wide css={[tw`relative pt-24 pb-10`]}>
          <div
            css={[tw`flex absolute items-center bg-white p-3 -top-6 right-0`]}
          >
            <SocialLinks />
          </div>
          <div
            css={[
              tw`grid md:grid-cols-7 gap-y-8 md:gap-8 mb-16 text-center md:text-left`,
            ]}
          >
            <div>
              <Logo withText={false} css={[tw`w-32 m-auto md:m-0`]} />
            </div>
            <div css={[tw`col-span-3 md:px-6`]}>
              <FooterNav />
            </div>
            <div css={[tw`md:col-start-5 md:col-span-3 mt-8 md:mt-0`]}>
              <p css={[tw`font-bold mb-4 md:mb-8`]}>{footer.jobs.paragraph}</p>
              <EmailSubmissionForm
                email_label="Your email adress"
                cta="Send"
                inline
                dark
                id="careers"
              />
            </div>
          </div>
          <p css={[tw`text-center text-sm`]}>
            &copy;{new Date().getFullYear()} deCommerce. All rights reserved |{" "}
            <Link to="terms-and-conditions">Terms &amp; Conditions</Link> |{" "}
            <Link to="privacy-policy">Privacy Policy</Link>
          </p>
        </Container>
      </footer>
    </>
  )
}

export default Footer
