import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import tw from "twin.macro"

const FooterNav = () => {
  const data = useStaticQuery(graphql`
    {
      allNavigationYaml {
        nodes {
          label
          url
        }
      }
    }
  `)
  return (
    <nav>
      <ul css={[tw`grid grid-cols-2 gap-y-8 m-auto sm:w-7/12 md:w-11/12 lg:gap-8 lg:w-3/4 lg:ml-0`]}>
        {data.allNavigationYaml.nodes.map(item => (
          <li>
            <Link to={item.url} css={[tw`font-bold`]}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default FooterNav
