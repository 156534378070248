import React, { useState } from "react"
import tw from "twin.macro"
import Button from "../Button"
import { Input } from "../Form"

const EmailSubmissionForm = ({ email_label, cta, inline, dark, id }) => {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSuccess(false)
    setEmailError(false)
    setSubmitting("Submitting")

    // Validate email field
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // If is not valid email
    if (!re.test(String(email).toLowerCase())) {
      setEmailError("A valid email is required to sign up.")
      setSubmitting(false)
      // If is valid email
    } else {
      const res = await fetch(`${process.env.GATSBY_EMAIL_SERVICE_URL}/email`, {
        method: "post",
        headers: {
          "x-api-key": process.env.GATSBY_EMAIL_SERVICE_KEY,
        },
        body: JSON.stringify({
          sheet_title: id,
          email: email,
        }),
      })
      const json = await res.json()
      if (res.status === 200) {
        setSubmitting(false)
        setEmail("")
        setSuccess(json.message)
      }
      if (res.status === 500) {
        setSubmitting(false)
        setEmailError(json.message)
      }
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      novalidate="novalidate"
      css={[inline && tw`flex flex-col sm:flex-row`]}
    >
      <div css={[tw`flex-1 w-full`, inline && tw`mr-4 mb-4 md:mb-0`]}>
        <Input
          label={email_label}
          type="email"
          name="email"
          onChange={e => setEmail(e.target.value)}
          value={email}
          error={emailError}
          required={true}
          css={[!inline && tw`mb-6`]}
        />
      </div>
      <Button
        primary={!dark}
        secondary={dark}
        large={!inline}
        type="submit"
        submitting={submitting}
      >
        {success || submitting || cta}
      </Button>
    </form>
  )
}

export default EmailSubmissionForm
