import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import tw from "twin.macro"
import * as icons from "../Icons"

const SocialLinks = ({ large }) => {
  const data = useStaticQuery(graphql`
    {
      allSocialLinksYaml {
        nodes {
          name
          icon
          url
        }
      }
    }
  `)

  return (
    <>
      {data.allSocialLinksYaml.nodes.map((social, i) => {
        const Icon = icons[social.icon]
        const last = i === data.allSocialLinksYaml.nodes.length - 1
        return (
          <a
            href={social.url}
            target="_blank"
            css={[
              tw`px-1 text-primary hover:(text-secondary) focus:(text-secondary) active:(text-primary)`,
              large && tw`mr-8`,
              last && tw`mr-0`,
            ]}
          >
            <Icon css={[tw`w-8`, large && tw`w-16`]} />
          </a>
        )
      })}
    </>
  )
}

export default SocialLinks
